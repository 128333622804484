@import './reset.css';

html {
  font-size: 62.5%; /* font-size 1em = 10px on default browser settings */
  font-family: 'DM Sans', sans-serif;
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  font-size: 1.6rem;
}

#root {
  position: absolute;
  height: 100%;
  width: 100%;
}

:root {
  --color-dark-1: #042240;
  --color-dark-2: #9e9e9e;
  --color-dark-3: #e3e3e3;
  --color-light-1: #ffffff;
  --color-light-2: #cfd8dc;
  --color-err-1: #ff0c3e;
  --color-success-1: #00ba35;
  --color-info-1: #8244e5;
  --color-info-2: #3700b3;
  --color-link: #3b47ef;
  --color-nav-1: rgb(0, 207, 255);
  --color-nav-2: rgb(38, 92, 221);
}

.IntlPhone > input{
  width: 100% !important;
  height: 45px !important;
}